@import '~@csstools/normalize.css';
@import 'modern-normalize';
@import './stylesheet/fonts.css';
@import './stylesheet/vars.css';

body {
  font-family: 'ProximaNova-400',  sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.18px;
  color: var(--main-text);
  background: var(--bg-main);
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  display: block;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

button {
  margin: 0;
  padding: 0;
  font-family: inherit;
  cursor: pointer;
}

img {
  display: block;
}

.sl-wrapper .sl-counter, .sl-wrapper .sl-close,
.sl-wrapper .sl-navigation button.sl-prev,
.sl-wrapper .sl-navigation button.sl-next {
color: var(--bg-primery) !important;
}

.sl-overlay {
  background: rgb(234, 231, 231);
}

.PhoneInput {
  margin-bottom: 4px;
  position: relative;
}

.PhoneInputCountry {
  position: absolute !important;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.PhoneInputCountrySelectArrow {
  color: var(--bg-primery);
}

.PhoneInputInput {
  padding: 14px 12px 14px 63px;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-text);
  background-color: var(--bg-main);
  border: none;
  outline: transparent;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.PhoneInputInput:focus {
  outline: 2px solid var(--bg-primery);
}

.PhoneInputCountrySelect {
  height: 59px;
  width: 55px;
  left: -13px;
  top: -10px;
}

.PhoneInputCountrySelect option {
  color: var(--primery-text);
  font-family: 'ProximaNova-500';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

/* Для веб-кит браузеров (Chrome, Safari) */
.PhoneInputCountrySelect::-webkit-scrollbar {
  width: 5px; /* Ширина полосы прокрутки */
}

.PhoneInputCountrySelect::-webkit-scrollbar-thumb {
  background-color: var(--bg-brown); /* Цвет ползунка */
  border-radius: 5px; /* Скругление углов ползунка */
}
